<template>
  <div class="">
    <b-card class="">
      <h4><i class="fa fa-fill"></i> Create Cost Code Buckets Template</h4>
      <hr>
      <div class="row">
        <div class="col-md-6 col-xl-6 mt-2">
          <h5>Select Projects <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="You may select multiple projects"></i></h5>
          <multiselect @input="get_afes()" v-model="projectids" placeholder="Search or add project" tag-placeholder="Search or add project" track-by="projectid" :options="projectData" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="true" label="projectname" :preselect-first="true">
          </multiselect>
        </div>
        <div class="col-md-6 col-xl-6 mt-2" v-if="afeData.length > 0">
          <h5>Select AFE / PO</h5>
          <multiselect v-model="afes" ref="afeData" @input="get_chains()" placeholder="Search or add afe" tag-placeholder="Search or add afe" track-by="pwcaid" :options="afeData" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="true" label="afenum" :preselect-first="false">
          </multiselect>
        </div>
      </div>
    </b-card>
    <b-card v-if="allBuckets.length >0">
      <v-client-table class="bucket-table" stacked :columns="allBucketColumns" :data="allBuckets" :options="allBucketOptions" :theme="theme" id="chain-data">
        <div slot="delete" slot-scope="props">
          <button type="button" @click="removeFromAllBucket(props.row)" class="btn btn-danger btn-sm" name="button">
            <i class="fa fa-minus-circle"></i>
          </button>
        </div>
        <template slot="child_row" class="first-child-toggler" slot-scope='props'>
          <v-client-table class="rt-table-striped chain-data-child-table" :data="props.row.cc_group" :columns="childAllBucketColumnsAssignedServices" :options="childAllBucketOptionsAssignedServices" :theme="theme">
            <div slot="cc3" slot-scope="props">
              <span v-if="!props.row.cc3.includes('---')">{{props.row.cc3}}</span>
            </div>
          </v-client-table>
        </template>
      </v-client-table>

      <div class="row">
        <div class="col-md-6 col-xl-6 mt-2">
          <h5>Bucket Template Name</h5>
          <input type="text" name="bucket" v-model="bucketTemplateName" class="form-control" placeholder="Bucket Name">
        </div>
        <div class="col-md-6 col-xl-6 mt-2">
          <h5>&nbsp;</h5>
          <button type="button" @click="createBucket()" class="btn btn-info" style="color:#fff" name="button">
            Save Bucket Template <i class="fa fa-save"></i>
          </button>
        </div>
      </div>
    </b-card>
    <b-card class="">
      <div class="row">
        <div class="col-md-6 col-xl-6 mt-2">
          <h5>Bucket Name</h5>
          <input type="text" name="bucket" v-model="bucketName" class="form-control" placeholder="Bucket Name">
        </div>
        <div class="col-md-6 col-xl-6 mt-2">
          <h5>&nbsp;</h5>
          <button type="button" @click="addBucketToBuckets()" class="btn btn-success" name="button">
            Add Bucket To Template <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="col-md-12 mt-3">
          <v-client-table v-if="bucket.length >0" class="chain-table" stacked :columns="bucketColumns" :data="bucket" :options="bucketOptions" :theme="theme" id="chain-data">
            <div slot="delete" slot-scope="props">
              <button type="button" @click="removeFromBucket(props.row)" class="btn btn-danger btn-sm" name="button">
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
            <div slot="cc3" slot-scope="props">
              <span v-if="!props.row.cc3.includes('---')">{{props.row.cc3}}</span>
            </div>
          </v-client-table>
        </div>
      </div>
      
      <v-client-table v-if="groupChainData.length > 0" class="chain-table mt-3" stacked :columns="columnsAssignedServices" :data="groupChainData" :options="optionsAssignedServices" :theme="theme" id="chain-data">
        <template slot="child_row" class="first-child-toggler" slot-scope='props'>
          <v-client-table class="rt-table-striped chain-data-child-table" :data="props.row.children" :columns="childColumnsAssignedServices" :options="childOptionsAssignedServices" :theme="theme">
            <div slot="name" slot-scope="props">
              <span v-if="props.row.code[0].cc_num == 2" style="font-weight: bold; font-size: 1.2em;">{{props.row.name}}</span>
              <span v-else>{{props.row.name}}</span>
              <button type="button" class="ml-2 btn-sm btn-secondary" @click="addToBucket(props.row)" name="button" v-if="props.row.code[0].cc_num == 2">
                Add To Bucket <i class="fa fa-plus"></i>
              </button>
            </div>

            <template slot="child_row" slot-scope='props'>
              <v-client-table class="rt-table-striped chain-data-child-table" :data="props.row.children.filter(x => !x.name.includes('---'))" :columns="childColumnsAssignedServices" :options="childOptionsAssignedServices" :theme="theme">
                <div slot="name" slot-scope="props">
                  <div v-if='props.row.name.includes("---")'>

                  </div>
                  <div v-else style="font-weight: bold; font-size: 1.2em;">
                    <i class="fa fa-arrow-circle-right ml-3 mr-2"></i>{{props.row.name}}

                    <button type="button" class="ml-2 btn-sm btn-secondary" @click="addToBucket(props.row)" name="button">
                      Add To Bucket <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </v-client-table>
            </template>
          </v-client-table>
        </template>
      </v-client-table>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

export default {
  name: "CostCodeBuckets",
  components: {
    Multiselect,
    ClientTable,
    RotateSquare2,
  },
  data : function () {
    return {
      projectData: [],
      afeData: [],
      projectids: [],
      afes: [],
      chainData: [],
      bucketName: "",
      theme: 'bootstrap4',
      template: 'default',
      bucket: [],
      allBuckets: [],
      //Services Data Table
      allBucketColumns: ['name', 'delete'],
      allBucketOptions: {
        headings: {
          delete: 'Delete',
          name: 'Bucket Name',
        },
        sortable: ['cc1', 'cc2', 'cc3', 'afenum'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 10
      },
      bucketColumns: ['delete', 'afe', 'cc1', 'cc2', 'cc3'],
      bucketOptions: {
        headings: {
          delete: '',
          afe: 'AFE / PO',
          cc1: 'Cost Code 1 / Major',
          cc2: 'Cost Code 2 / Minor',
          cc3: 'Cost Code 3 / Description',
        },
        sortable: ['cc1', 'cc2', 'cc3', 'afenum'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 10
      },
      optionsAssignedServices: {
        headings: {
          cc1: 'Cost Code 1 / Major',
          cc2: 'Cost Code 2 / Minor',
          cc3: 'Cost Code 3 / Description',
        },
        sortable: ['serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      childAllBucketColumnsAssignedServices: ['afe', 'cc1', 'cc2', 'cc3'],
      childAllBucketOptionsAssignedServices: {
        headings: {
          afe: 'AFE / PO',
          cc1: 'Cost Code 1 / Major',
          cc2: 'Cost Code 2 / Minor',
          cc3: 'Cost Code 3 / Description',
        },
        sortable: ['serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      columnsAssignedServices: ['name'],
      optionsAssignedServices: {
        headings: {
          name: 'Codes',
        },
        sortable: ['serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      childColumnsAssignedServices: ['name'],
      childOptionsAssignedServices: {
        headings: {
          name: 'Cost Code',
        },
        sortable: ['status', 'ccone_code', 'cctwo_code', 'ccthree_code', 'serviceName', 'serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      lastChildColumnsAssignedServices: ['serviceName', 'total', 'delete'],
      lastChildOptionsAssignedServices: {
        headings: {
          serviceName: 'Service Name',
          total: 'Total',
          delete: 'Delete',
        },
        sortable: ['code', 'name'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      bucketTemplateName: "",
      companyid: 0,
    }
  },
  computed: {
    groupChainData: function() {
      // Get all the required ids
      var afes = this.afes.map(x=>x.pwcaid);

      var filteredByAfenums = this.chainData.filter(function(a) {
        // Check if both source and target are present in list of ids
        return afes.indexOf(a.pwcaid) !== -1 && afes.indexOf(a.pwcaid) !== -1;
      });

      var chainData = this.lodash.chain(filteredByAfenums)
      .groupBy('ccone_code')
      .map((code, name) => ({name, code }))
      .value();

      for (var i = 0; i < chainData.length; i++) {
        chainData[i].id = i;

        chainData[i].children = this.lodash.chain(chainData[i].code)
        .groupBy('cctwo_code')
        .map((code, name) => ({name, code }))
        .value();

        for (var x = 0; x < chainData[i].children.length; x++) {
          chainData[i].children[x].id = x;
          chainData[i].children[x].children = this.lodash.chain(chainData[i].children[x].code)
          .groupBy('ccthree_code')
          .map((code, name) => ({name, code }))
          .value();
        }
      }

      return chainData;
    }
  },
  methods : {
    addToBucket(row){
      console.log(row,"rowwwwww");
      this.bucket.push({
        "cc1": row.code[0].ccone_code,
        "cc1": row.code[0].ccone_code,
        "cc2": row.code[0].cctwo_code,
        "cc3": row.code[0].ccthree_code,
        "cc1id": row.code[0].cconeid,
        "cc2id": row.code[0].cctwoid,
        "cc3id": row.code[0].ccthreeid,
        "afe": row.code[0].afenum,
        "pwcaid":row.code[0].pwcaid,
        "service_ccid": row.code[0].service_ccid,
      })
    },
    removeFromBucket(row){
      var find = this.bucket.findIndex(x=>x.cc1 == row.cc1 && x.cc2 == row.cc2 && x.cc3 == row.cc3 && x.afe == row.afe && x.service_ccid == row.service_ccid)
      Vue.delete(this.bucket, find)
    },
    removeFromAllBucket(row){
      var find = this.allBuckets.findIndex(x=>x.name == row.name)
      Vue.delete(this.allBuckets, find)
    },
    addBucketToBuckets(){
      if(this.bucketName == "" || this.bucket.length == 0){
        this.$toasted.show('Bucket is not complete', {type: 'error', duration: '3000'})
        return
      }

      var cc_group = [];
      var projectids = this.projectids.map(x=>x.projectid);

      this.bucket.forEach(line => {
        cc_group.push({
          "cc1": line.cc1,
      		"cc2": line.cc2,
      		"cc3": line.cc3,
          "afe": line.afe,
          "pwcaid":line.pwcaid,
          "cc1id": line.cc1id,
          "cc2id": line.cc2id,
          "cc3id": line.cc3id,
      		"service_ccid": line.service_ccid
        })
      })

      this.allBuckets.push({
        "id": this.allBuckets.length,
        "cc_group": cc_group,
        "name": this.bucketName,
      })

      this.bucket = [];
      this.bucketName = [];
    },
    createBucket(){
      if(this.bucketTemplateName == "" || this.allBuckets.length == 0){
        this.$toasted.show('Bucket is not complete', {type: 'error', duration: '3000'})
        return
      }
      var cc_group = [];
      var projectids = this.projectids.map(x=>x.projectid);

      let data = JSON.stringify({
        "cc_group": this.allBuckets,
      	"name": this.bucketTemplateName,
      	"status": 1,
      	"company_id": this.projectids[0].companyid,
      	"project_id": projectids
      })

      this.$http.post('/cc_bucket/create/', data)
      .then((response) => {
        console.log(response)
        this.$toasted.show('Successfully Created Cost Code Bucket Template.', {type: 'success', duration: '3000'})
        this.$router.push("/pm/buckets")
      })
      .catch((error) => {
        console.log(error)
      })
    },
    get_projects(){
      if(this.$session.get('user') == 'pm'){
        this.$http.get('/wts/get/projects')
        .then(response => {
          this.projectData = response.data.result;
        })
        .catch(error => {
        })
      }
      else {
        this.$http.get('/manager/get/projects')
        .then(response => {
          this.projectData = response.data.result;
        })
        .catch(error => {
        })
      }

    },
    get_afes(){
      this.afes = [];
      this.afeData = [];
      this.chainData = [];

      for (var i = 0; i < this.projectids.length; i++) {
        if(this.$session.get('user') == 'pm'){
          this.$axios.get('/wts/get/afes/' + this.projectids[i].projectid).then(response => {
            this.afeData = this.afeData.concat(response.data.result);
              console.log(this.afeData,"afe dataaaa");
          })
        }
        else {
          this.$axios.get('/manager/get/wts_afe/' + this.projectids[i].projectid + '/' + this.companyid).then(response => {
            this.afeData = this.afeData.concat(response.data.result)
          })
        }
        this.$axios.get('/wts/get/assign_services/' + this.projectids[i].projectid).then(response => {
          this.chainData = this.chainData.concat(response.data.result)
        })
      }
    },
    get_chains(){
       
    }
  },
  mounted() {
  
    this.get_projects();

    if(this.$session.get('user') == 'manager' || this.$session.get('user') == 'accountant'){
      this.$axios.get('/manager/get/profile').then(response => {
        this.companyid = response.data.companyid;
      })
    }
  }
}
</script>

<style lang="scss">
  .bucket-table td:first-child {
    width: 5% !important;
  }

  #chain-data.bucket-table .VueTables__child-row .chain-data-child-table thead th {
    width: 25% !important;
  }

  #chain-data {
    .VuePagination__count {
      display: none !important;
    }
  }

  .bucket-table .chain-data-child-table {
    .table-responsive {
      padding-left: 5% !important;

      thead {
        display: table-header-group !important;
      }
    }
  }
  .bucket-table .VueTables__child-row > td{
    padding: 0 !important;
  }
  .well-datepicker {
    input {
      width: 100%;
      font-size: 100%;
      padding: 0.35rem;
    }
  }
  .rt-multi {
    width: 100% !important;
    .checkboxLayer {
      margin-top: 10px;
      width: 100%;
    }
    .btn-select {
      width: 100% !important;
      display: block !important;
      padding: 6px 40px !important;
    }
  }
  .disabled-dp {
    input {
      background: #e6e6e6;
    }
  }
  #dataTable.wellTable {
    table th:nth-child(3),
    table tr:nth-child(3){
      width: 110px;
    }
  }
  .dropdown-chev {
    position: absolute;
    top: 11px;
    right: 15px;
  }
  .vue-simple-suggest.designed .input-wrapper input {
    padding: 7px;
  }

  .afe-status-table td{
    width: 120px !important;
  }
  #loading-gif {
    z-index: 9999;
    position: absolute;
    top: 30%;
    right: 50%;
  }

  #chain-data {
    .VueTables__search-field {
      margin-top: 0.5em;
      label {
        display: none;
      }
    }

    @media screen and (min-width: 1200px){
      .table-responsive {
        overflow: hidden !important;
      }
    }

    div table thead tr th {
      background-color: #444;
      color: #fff;
      font-size: 1.2em;
    }
    td {
      vertical-align: middle;
    }
    div table thead tr th:first-child {
      //width: 5%;
    }
    .chain-data-child-table{
        tbody {
          //background-color: rgba(63, 233, 38, 0.09);
        }

        .VuePagination {
          margin-top: 2em;
        }

        th {
          background: #7d7d7d !important;
          color: #fff !important;
          font-size: 1em !important;
        }
        th {
          width: inherit !important;
        }
        th:first-child {
          //width: 5% !important;
        }

        .VueTables__child-row > td {
          padding: 0;
      }
    }
    .parent th {
      background: #444;
      color: #fff;
    }

    .checkslots {
      text-align: center;
      margin: 0 auto;
      display: block;
      margin-top: 2px;
      font-size: 4em;
    }
    .table td {
      padding: 0.55rem;
    }
    .VueTables__heading {
      text-align: left;
    }
    .VuePagination {
      text-align: center;
      justify-content: center;
    }

    .VueTables__search-field {
      display: flex;
    }
    .VueTables__search-field input {
      margin-left: 0.25rem;
    }

    .VueTables__limit-field {
      display: flex;
    }

    .VueTables__limit-field select {
      margin-left: 0.25rem !important;
    }

    .VueTables__table th {
      text-align: left;
    }

    .VueTables__child-row-toggler {
      width: 16px;
      height: 16px;
      line-height: 18px;
      background: #4dbd74;
      display: block;
      margin: auto;
      text-align: center;
      padding: 6px !important;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      line-height: 20px;
      color: #fff;
      font-weight: bold;
    }

    .VueTables__child-row-toggler--open {
      line-height: 17px !important;
    }

    .VueTables__child-row-toggler--open::before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 400 !important;
      content: "\f054" !important;
    }

     .VueTables__child-row-toggler--closed::before {
      content: "\f078" !important;
      font-weight: 400 !important;
      font-family: "Font Awesome 5 Pro" !important;
    }

  }
</style>
